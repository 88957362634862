<template>
  <UiPage
    :title="$t('app.page.news')"
    :titleTemplate="$t('app.page.news')"
    class="content-padding-mobile"
  >
    <template #top>
      <BackButton />
    </template>

    <template #content>
      <div
        class="grid"
        :style="{
          'grid-template-columns': `repeat(${_columns}, 1fr)`
        }"
      >
        <template v-if="!preloader">
          <NewsCard
            v-for="item in data"
            :key="item.id"
            :value="item"
          />
        </template>

        <template v-else>
          <div
            v-for="item in 4"
            :key="item"
            class="grid-preloader-card"
          >
            <Preloader
              :visible="preloader"
            />
          </div>
        </template>
      </div>

      <div class="controls">
        <Pagination
          :name="$route.name"
          :pages="_countItemPagination"
          :load-more="data.length < count"
          :loading="isFetchMore"
          @loadMore="fetchMore()"
        />
      </div>
    </template>
  </UiPage>
</template>

<script>
import UiPage from '@/ui/Page.vue'
import BackButton from '@/ui/buttons/BackButton.vue'
import NewsCard from '@/ui/NewsCard.vue'
import Preloader from '@/ui/preloader/SkeletonAnimatePreloader.vue'
import Pagination from '@/ui/Pagination.vue'

export default {
  components: {
    UiPage,
    BackButton,
    NewsCard,
    Preloader,
    Pagination
  },
  data () {
    return {
      mountFetch: false,
      preloader: true,
      isFetchMore: false,
      length: 4,
      count: 0,
      data: []
    }
  },
  computed: {
    _columns () {
      return this.$store.state.window.to768 ? 1
        : this.$store.state.window.width <= 1570 && this.$store.state.window.width >= 1280 ? 3
          : this.$store.state.window.width < 1280 ? 2 : this.length
    },
    _offset () {
      return ((this.$route.params.page ? this.$route.params.page : 1) * (this.length * 3)) - (this.length * 3)
    },
    _countItemPagination () {
      if (this.count) {
        return Math.ceil(this.count / (this.length * 3))
      }

      return 0
    }
  },
  activated () {
    if (this.mountFetch) {
      this.fetch()
    }
  },
  mounted () {
    this.fetch()
  },
  deactivated () {
    if (this.data.length > this.length * 3) {
      this.data = this.data.slice(0, this.length * 3)
    }
  },
  methods: {
    async fetch () {
      try {
        const resp = await this.$axios.get(
          `/private/news?limit=${this.length * 3}&offset=${this._offset}`)

        if (resp.status === 200) {
          this.count = resp.data.count
          this.data = resp.data.data

          if (this.$route.params.page > 1 && this.data.length === 0) {
            this.$store.commit('SET_ERROR', true)
          }
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        this.preloader = false
        this.mountFetch = true
      }
    },
    async fetchMore () {
      this.isFetchMore = true
      const offset = this._offset + (this.data.length ? this.data.length : 0)
      const link = `/private/news?offset=${offset}&limit=${this.length}`

      try {
        const resp = (await this.$axios.get(link)).data
        this.count = resp.count

        for (const i in resp.data) {
          this.data.push(resp.data[i])
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        this.isFetchMore = false
      }
    }
  },
  watch: {
    '$route.params.page' () {
      if (this.$route.params.page) {
        this.fetch()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  gap: 30px 20px;

  .grid-preloader-card {
    position: relative;
    height: 420px;
    background: #FFFFFF;
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.06);
    border-radius: 15px;
    overflow: hidden;
  }
}

.controls {
  margin-top: 40px;
}
</style>
